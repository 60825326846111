<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">任务列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入任务名称" class="iw" ></el-input>
                <el-input v-model="landName" placeholder="请输入地块名称" class="iw" ></el-input>
                <el-input v-model="userName" placeholder="请输入作业人员名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" > 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <!-- <el-table-column prop="ZSBH" label="地块证书编号"></el-table-column> -->
                    <el-table-column prop="MJ_M" label="面积（亩）" ></el-table-column>
                    <el-table-column prop="LandName" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="STATUS" label="任务状态" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 0">待分配至作业单位</span>
                            <span v-else-if="scope.row.STATUS== 1">待分配至作业人员</span>
                            <span v-else-if="scope.row.STATUS== 2">作业人员待提交</span>
                            <span v-else-if="scope.row.STATUS== 3">正常提交</span>
                            <span v-else-if="scope.row.STATUS== 4">异常标注</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="GIVETIME" label="分配时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0">{{scope.row.GIVETIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0">{{scope.row.WorkUnitName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0 && scope.row.STATUS!= 1">{{scope.row.WorkUserName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LASTSUBMITTIME" label="最近一次提交时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 3||scope.row.STATUS== 4">{{scope.row.LASTSUBMITTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
 
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="addPost(scope.row)" type="text" size="small" style="color:#409EFF">提交成果</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="宗地名称" prop="landName">
                    <el-input v-model="fruitInfo.landName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="宗地类型" prop="landTypeSnowID">
                    <el-select v-model="fruitInfo.landTypeSnowID" placeholder="请选择宗地类型"  filterable  style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用单位" prop="sydw">
                    <el-input v-model="fruitInfo.sydw" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-input v-model="fruitInfo.gyqk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-input v-model="fruitInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-input v-model="fruitInfo.qlxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-input v-model="fruitInfo.yt" autocomplete="off"></el-input>
                </el-form-item>
               
                <el-form-item label="使用期限" prop="syqx">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdw">
                    <el-select v-model="fruitInfo.fzdw" placeholder="请选择发证地区"  filterable allow-create style="width:100%;">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <!-- prop="txt" -->
                <el-form-item label="地块范围坐标文件" >
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunB"
                        :before-upload="beforeAvatarUploadB">
                        <span v-if="txtFileName!= ''" style="color:#409EFF;">{{txtFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="txtFileName!= ''" type="danger" size="mini" @click="deleteFileB()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="证书扫描件上传" prop="smj">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunC"
                        :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName!= ''" style="color:#409EFF;">{{smjFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="smjFileName!= ''" type="danger" size="mini" @click="deleteFileC()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunD"
                        :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName!=''" style="color:#409EFF;">{{cadFileName}}</span>
                        <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="cadFileName!=''" type="danger" size="mini" @click="deleteFileD()">取消选择</el-button>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFruitForm()">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "Task",
  data() {
    var landName=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var landTypeSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择宗地类型"));
      } else {
        callback();
      }
    };

    var sydw=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用单位"));
      } else {
        callback();
      }
    };

    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入发证单位"));
      } else {
        callback();
      }
    };

    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传证书扫描件"));
      } else {
        callback();
      }
    };

    var txt=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传地块范围坐标文件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };
    return {
        zydwid:"",
        canDo:true,
        showPagination:false,
        name:"",
        userName:"",
        landName:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landName: [{ validator: landName, trigger: "blur" }],
            landTypeSnowID: [{ validator: landTypeSnowID, trigger: "blur" }],
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            sydw: [{ validator: sydw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdw: [{ validator: fzdw, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
            // txt:[{ validator: txt, trigger: "blur" }],
        },
        zdlxList:[],


        txtArea:0,
        txtFile:null,
        txtFileSrc:"",
        txtFileId:"",
        txtFileName:"",
        chooseTxtFile:false,
        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,
    };
  },
  mounted() {
    this.loadItem=null
    this.getZydwid()
    this.getZdlx()
    this.getFzdwList()
    this.getEjdwList()
  },
  methods: {
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getZdlx(){
        this.canDo=false
        this.$http.post("LandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getZydwid(){
        this.canDo=false
        this.$http.post("ResultUser/GetDataList", {snowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].WorkUnitSnowID
                    this.canDo=true
                    this.getPostList()
                }else{
                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    //获取任务
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
            stutas:"3",
            workUnitName:"",
            workUserName:this.userName,
            landName:this.landName,
            WorkUnitSnowID:this.zydwid,
            giveStartTime:"",
            giveEndTime:"",
            LastSubmitStartTime:"",
            LastSubmitEndTime:"",
            type:"2",
            landSnowID:"",
            EJDW:"",
            HaveExchangeResult:"0",
        }
        this.$http.post("Task/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },




    addPost(item){
        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.txtArea=0

        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.doTypeStr=item.LandName+"成果汇交登记"
        console.log(item)

        this.fruitInfo={
            landSnowID:item.LANDSNOWID,
            landName:item.LandName,
            landTypeSnowID:"",
            landStatus:"",
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            sydw:"",
            zsbh:"",
            qlr:"",
            gyqk:"",
            zl:"",
            bdcdyh:"",
            qllx:"",
            qlxz:"",
            yt:"",
            mj:"",
            syqx:"",
            qlqtzk:"",
            fzdw:"",
            smj:"",
            txt:"",
        }
        this.doType="2"
        this.fruitModal=true
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    delete  this.fruitInfo.txt
                    this.sendFAdd()
                } else {
                    return false;
                }
            });
        }
    },
    sendFAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/InsertData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    this.getPostList()
                    if(this.chooseTxtFile){
                        this.addImgB(response.data.DATA)
                    }
                    if(this.chooseSmjFile){
                        this.addImgC(response.data.DATA)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(response.data.DATA)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    beforeAvatarUploadB(file) {
        const isJPG = file.type === 'text/plain';
        if (!isJPG) {
        this.$message.error('坐标范围文件只能是 TXT  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(file);
            this.chooseTxtFile=true
            this.txtFile = file
            this.txtFileName=file.name
            this.fruitInfo.txt="1"
        }
        return isJPG;
    },
    uploadFunB(file){
        this.txtArea=0
        //计算面积
    },
    deleteFileB(){
        this.txtFile =null
        this.txtFileSrc=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.fruitInfo.txt=""

        // if( this.txtFileId!=""){
        //     this.sendDelFile(this.txtFileId)
        // }
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        // if( this.smjFileId!=""){
        //     this.sendDelFile(this.smjFileId)
        // }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        // if( this.cadFileId!=""){
        //     this.sendDelFile(this.cadFileId)
        // }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgB(id){
        var fd = new FormData();
        fd.append("file", this.txtFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=7&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },
    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
</style>